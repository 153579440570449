// import Typography from 'typography'
// import CodePlugin from 'typography-plugin-code'
// import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'
// import irving from 'typography-theme-grand-view'


// const options = {
//   baseFontSize: '16px',
//   baseLineHeight: 1.5,
//   bodyFontFamily: ['quicksand', 'Georgia', 'serif'],
//   headerFontFamily: ['irving', 'Helvetica', 'sans-serif'],
//   scaleRatio: 2.25,
//   plugins: [new CodePlugin()],
//   overrideStyles: () => ({
//     [MOBILE_MEDIA_QUERY]: {
//       // Make baseFontSize on mobile 16px.
//       html: {
//         fontSize: `${16 / 16 * 100}%`
//       }
//     }
//   })
// }

// const typography = new Typography(irving)
// const { rhythm } = typography;




// export default typography


import Typography from "typography"
// import bootstrapTheme from "typography-theme-bootstrap"
import lawtonTheme from "typography-theme-st-annes";

const typography = new Typography(lawtonTheme)
const { rhythm } = typography;
export { rhythm };
export default typography