import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled from 'react-emotion'
import Menu from '../components/Menu'
// import Content from '../components/Content'
import Footer from '../components/Footer'
// import Header from '../components/Header'

import '../css/base.css'

const Root = styled.div`
  height: 100vh;
  width: 100%;
`

export default class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  render() {
    const { children, location } = this.props
    // const isPost =
    //         location.pathname !== '/' && !location.pathname.match(/^\/blog\/?$/)
    return (
        <Root>
        <Helmet
          title="lisp-ceo"
          meta={[
            {
              name: 'description',
              content:
              ''
            },
            {
              name: 'keywords',
              content:
              ''
            }
          ]}>
        <html lang="en" />
        </Helmet>
        <Menu />
        {children}
        </Root>
    )
  }
}
