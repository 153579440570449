module.exports = [{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-emotion/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-twitter/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-layout/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/htmldrum/code/github/lisp_ceo/blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-79917146-3"},
    }]
