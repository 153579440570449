import React from 'react'
import styled from 'react-emotion'
import GatsbyLink from 'gatsby-link'
import { rhythm } from '../utils/typography';
import './menu.css';
import smallFayce from '../images/fayceSmall.png'
import smallBlog from '../images/blogSmall.png'
import smallMegaphone from '../images/megaphoneSmall.png'

const Content = styled.main`
position: absolute;
top: 0;
right: 0;
margin: 30px;
max-width: 840px;
z-index: 2;
color: black;`


const Image = styled.img`
width: 20px;
@media only screen and (max-width: 600px) {
  width: 100%;
} 
@media only screen and (min-width: 600px) {
  width: 50px;
}
`

const ContentType = styled.main`
  margin: 10px;
`

export default function MenuContent({ children, Footer, ...rest }) {
    const hideMenu = true
    if (hideMenu) {
	return (
		<Content className="Content hideMenu">
		<ContentType className="ContentType home">
		<GatsbyLink
            to="/"
            activeClassName="active">
		<Image src={smallFayce} />
		</GatsbyLink>
		</ContentType>
		<ContentType className="ContentType blog">
		<GatsbyLink
            to="/blog"
            activeClassName="active">
		<Image src={smallBlog} />
		</GatsbyLink>
		</ContentType>
		<ContentType className="ContentType contact">
		<a href="https://twitter.com/lisp_ceo" target="_blank" rel="noopener norefferer">
		<Image src={smallMegaphone} />
		</a>
		</ContentType>
		</Content>)
    } else {
	return (
		<Content className="Content">
		<ContentType className="ContentType home">
		<GatsbyLink
            to="/"
            activeClassName="active">
		<Image src={smallFayce} />
		</GatsbyLink>
		</ContentType>
		<ContentType className="ContentType blog">
		<GatsbyLink
            to="/blog"
            activeClassName="active">
		<Image src={smallBlog} />
		</GatsbyLink>
		</ContentType>
		<ContentType className="ContentType contact">
		<a href="https://twitter.com/lisp_ceo" target="_blank" rel="noopener norefferer">
		<Image src={smallMegaphone} />
		</a>
		</ContentType>
		</Content>)
    }

}
